<template>
    <div class="notification_banner" :class="themeClass">
        <div class="notification_banner__content">
            <slot name="icon"></slot>
            <div class="notification_banner__spacer"></div>
            <slot></slot>
        </div>
        <button v-if="closeable" @click="requestClose" class="notification_banner__close_button">
            <font-awesome-icon class="notification_banner__close_icon" :icon="['fas', 'times']" />
        </button>
    </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faTimes);

export default {
    name: "NotificationBanner",
    components: {
        FontAwesomeIcon,
    },
    props: {
        closeable: {
            type: Boolean,
            default: true,
        },
        theme: {
            type: String,
            default: "navy",
        },
    },
    computed: {
        themeClass() {
            return `notification_banner--${this.theme}`;
        },
    },
    methods: {
        requestClose() {
            this.$emit("onRequestClose");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.notification_banner {
    @extend %body1_style;
    min-height: 50px;
    border-radius: 0px;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    * {
        display: inline-block;
    }
    &.notification_banner--navy {
        background: $grey_alabaster;
        color: $navy;
        border-bottom: 1px solid $grey_alabaster;
    }
    &.notification_banner--green {
        background: $green;
        color: $black;
    }
    &.notification_banner--red {
        background: $red;
        color: $white;
    }
}
.notification_banner__content {
    padding-right: 12px;
}
.notification_banner__spacer {
    width: 8px;
    display: inline-block;
}
.notification_banner__close_button {
    background: transparent;
    border: 0px;
    color: inherit;
    &:hover {
        cursor: pointer;
    }
}
.notification_banner__close_icon {
    color: inherit;
    font-size: 20px;
}
</style>
