<template>
    <div class="config" v-if="isReady">
        <NotificationBanner
            v-if="isOutdated"
            theme="red"
            :closeable="false"
            class="conflict_banner"
        >
            <template v-slot:icon>
                <font-awesome-icon
                    size="lg"
                    :icon="['far', 'history']"
                    class="conflict_banner__icon"
                />
            </template>
            <template v-slot:default>
                {{
                    $t(
                        "cp__invitations__settings__outdated_settings",
                        "Looks like you have outdated invitation settings."
                    )
                }}
                <a @click="reloadForm" class="conflict_banner__link">
                    {{
                        $t("cp__invitations__settings__outdated_settings__link", "Reload the form")
                    }}
                </a>
                {{
                    $t(
                        "cp__invitations__settings__outdated_settings__get_updated_version",
                        "to get the updated version."
                    )
                }}
            </template>
        </NotificationBanner>
        <SettingsHeader
            :title="$t('config_invitation__title', 'Invitation Settings')"
            :subtitle="
                $t(
                    'config_invitation__helptext',
                    'Here you can update your invitation notifications and settings to optimise your feedback service.'
                )
            "
            :backButtonTo="{ name: 'Invitations' }"
            :backButtonLabel="$t('cp__invitations__settings__back_button')"
            :class="{ config__header__with_conflict: isOutdated }"
        />
        <SettingsForm class="settings_form">
            <SettingsBlock
                class="config__block"
                :title="$t('config_invitation__notifications__title', 'Notifications')"
            >
                <SettingsSection
                    :label="
                        $t(
                            'config_invitation__notifications__api_email__label',
                            'API Notification email address'
                        )
                    "
                    :helpText="
                        $t(
                            'config_invitation__notifications__api_email__helptext',
                            'The Notification Mails regarding the API will be send here.'
                        )
                    "
                >
                    <EmailInputList
                        class="notification_emails"
                        v-model="localSettings.notificationSettings.notificationEmails"
                        :maxSize="10"
                        :enableExternalValidation="true"
                        :externalValidationErrors="notificationEmailsErrorMap"
                        :externalValidationErrorMessages="notificationsEmailErrorMessagesMap"
                        :addAnotherLabel="$t('cp__add_another_label')"
                    />
                </SettingsSection>

                <SettingsSection
                    :label="
                        $t(
                            'config_invitation__notifications__api_request_fail_warning__label',
                            'Send notifications'
                        )
                    "
                    :helpText="
                        $t(
                            'config_invitation__notifications__api_request_fail_warning__helptext',
                            'This notifies you when a couple API calls fail in a row.'
                        )
                    "
                >
                    <RadioList
                        required
                        enableExternalValidation
                        v-model="
                            localSettings.notificationSettings.apiRequestFailNotificationEnabled
                        "
                        :options="[
                            {
                                value: false,
                                displayValue: $t(
                                    'config_invitation__notifications__api_request_fail_warning__label__no_notification'
                                ),
                            },
                            {
                                value: true,
                                displayValue: $t(
                                    'config_invitation__notifications__api_request_fail_warning__label__send_notification'
                                ),
                            },
                        ]"
                    />
                </SettingsSection>

                <SettingsSection
                    :label="
                        $t(
                            'config_invitation__notifications__api_connection_fail_warning__label',
                            'Connection warning'
                        )
                    "
                    :helpText="
                        $t(
                            'config_invitation__notifications__api_connection_fail_warning__helptext',
                            'This notification can warn you when no successful order was received for an amount of days.'
                        )
                    "
                >
                    <RadioList
                        required
                        enableExternalValidation
                        v-model="localSettings.notificationSettings.apiConnectionWarningEnabled"
                        :options="[
                            {
                                value: false,
                                displayValue: $t(
                                    'config_invitation__notifications__no_notifications',
                                    'No notifications'
                                ),
                            },
                            {
                                name: 'apiConnectionWarningPeriod',
                                value: true,
                                isComponent: true,
                            },
                        ]"
                    >
                        <template v-slot:apiConnectionWarningPeriod>
                            <NaturalLanguageInput
                                center
                                v-model="
                                    localSettings.notificationSettings.apiConnectionWarningPeriod
                                        .value
                                "
                                :label="
                                    $t(
                                        'config_invitation__notifications__nli__send_notifications_after',
                                        'Send notifications [[value]] days'
                                    )
                                "
                                :inputAttributes="{
                                    type: 'number',
                                    style: 'text-align: center; width: 56px;',
                                }"
                                :validationError="
                                    $v.localSettings.notificationSettings.apiConnectionWarningPeriod
                                        .value.$error
                                "
                            />
                        </template>
                    </RadioList>
                    <ValidationMessage
                        :displayInvalid="
                            $v.localSettings.notificationSettings.apiConnectionWarningPeriod.value
                                .$error
                        "
                        :validationMessage="
                            resolveErrorMessage(
                                $v.localSettings.notificationSettings.apiConnectionWarningPeriod
                                    .value
                            )
                        "
                    />
                </SettingsSection>
            </SettingsBlock>

            <SettingsBlock
                class="config__block"
                :title="$t('config_invitation__invitations__title', 'Invitations')"
            >
                <SettingsSection
                    :label="
                        $t('config_invitation__invite__default_reminder__label', 'Default reminder')
                    "
                    :helpText="
                        $t(
                            'config_invitation__invite__default_reminder__helptext',
                            'When creating a manual invite in the Customer Portal, the reminder will be send:'
                        )
                    "
                >
                    <RadioList
                        required
                        enableExternalValidation
                        v-model="localSettings.inviteSettings.defaultReminderEnabled"
                        :options="[
                            {
                                value: false,
                                displayValue: $t(
                                    'config_invitation__invite__no_default_reminder',
                                    'No default reminder'
                                ),
                            },
                            {
                                name: 'defaultReminderPeriod',
                                value: true,
                                isComponent: true,
                            },
                        ]"
                    >
                        <template v-slot:defaultReminderPeriod>
                            <NaturalLanguageInput
                                center
                                v-model="localSettings.inviteSettings.defaultReminderPeriod.value"
                                :label="
                                    $t(
                                        'config_invitation__invitations__nli__remind_date_is',
                                        'After [[value]] days'
                                    )
                                "
                                :inputAttributes="{ type: 'number' }"
                                :validationError="
                                    $v.localSettings.inviteSettings.defaultReminderPeriod.value
                                        .$error
                                "
                            />
                        </template>
                    </RadioList>
                    <ValidationMessage
                        :displayInvalid="
                            $v.localSettings.inviteSettings.defaultReminderPeriod.value.$error
                        "
                        :validationMessage="
                            resolveErrorMessage(
                                $v.localSettings.inviteSettings.defaultReminderPeriod.value
                            )
                        "
                    />
                </SettingsSection>

                <SettingsSection
                    :label="
                        $t('config_invitation__invite__repeat_invites__label', 'Repeat Invites')
                    "
                    :helpText="
                        $t(
                            'config_invitation__invite__repeat_invites__helptext',
                            'After receiving an invitation, the customer will not receive any new invitations for this amount of time:'
                        )
                    "
                >
                    <RadioList
                        required
                        enableExternalValidation
                        v-model="localSettings.inviteSettings.repeatInvitesSuppressionEnabled"
                        :options="[
                            {
                                value: false,
                                displayValue: $t(
                                    'config_invitation__invitations__always_send_invitations',
                                    'Always send invitations.'
                                ),
                            },
                            {
                                name: 'repeatInvitesSuppressionEnabled',
                                value: true,
                                isComponent: true,
                            },
                        ]"
                    >
                        <template v-slot:repeatInvitesSuppressionEnabled>
                            <NaturalLanguageInput
                                center
                                v-model="localSettings.inviteSettings.repeatInvitesPeriod.value"
                                :label="
                                    $t(
                                        'config_invitation__invitations__send_no_new_invitations_within'
                                    )
                                "
                                :inputAttributes="{ type: 'number' }"
                                :validationError="
                                    $v.localSettings.inviteSettings.repeatInvitesPeriod.value.$error
                                "
                            >
                                <div class="natural_language_input__dropdown">
                                    <Dropdown
                                        required
                                        compact
                                        fontWeightRegular
                                        v-model="
                                            localSettings.inviteSettings.repeatInvitesPeriod.unit
                                        "
                                        :placeholder="
                                            $t(
                                                'config_invitation__invitations__timespan_dropdown_placeholder',
                                                'Time'
                                            )
                                        "
                                        enableExternalValidation
                                        :externalValidationError="
                                            $v.localSettings.inviteSettings.repeatInvitesPeriod.unit
                                                .$error
                                        "
                                        externalValidationErrorMessage=""
                                        :options="[
                                            {
                                                value: 'HOURS',
                                                displayValue: $t(
                                                    'generic__timeunits__hours',
                                                    'Hours'
                                                ),
                                            },
                                            {
                                                value: 'DAYS',
                                                displayValue: $t(
                                                    'generic__timeunits__days',
                                                    'Days'
                                                ),
                                            },
                                            {
                                                value: 'WEEKS',
                                                displayValue: $t(
                                                    'generic__timeunits__weeks',
                                                    'Weeks'
                                                ),
                                            },
                                            {
                                                value: 'MONTHS',
                                                displayValue: $t(
                                                    'generic__timeunits__months',
                                                    'Months'
                                                ),
                                            },
                                        ]"
                                    />
                                </div>
                            </NaturalLanguageInput>
                        </template>
                    </RadioList>
                    <ValidationMessage
                        :displayInvalid="
                            $v.localSettings.inviteSettings.repeatInvitesPeriod.value.$error ||
                            $v.localSettings.inviteSettings.repeatInvitesPeriod.unit.$error
                        "
                        :validationMessage="resolveRepeatInvitesPeriodErrorMessage"
                    />
                </SettingsSection>
            </SettingsBlock>
        </SettingsForm>

        <SettingsFooter>
            <Button v-if="isOutdated" class="btn__reload_form" @click.native="reloadForm">
                <font-awesome-icon class="action__action_icon" :icon="['far', 'undo']" />
                <span>{{ $t("portal instellingen (reload button)", "Reload form") }}</span>
            </Button>
            <Button
                @click.native="saveSettings"
                :disabled="isOutdated || !formIsDirty"
                :loading="isSaving"
            >
                {{ $t("portal instellingen (button)", "Save changes") }}
            </Button>
        </SettingsFooter>
    </div>
</template>

<script>
import NaturalLanguageInput from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/NaturalLanguageInput.vue";
import EmailInputList from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/EmailInputList.vue";
import SettingsHeader from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/Settings/SettingsHeader.vue";
import ValidationMessage from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/ValidationMessage/ValidationMessage.vue";
import RadioList from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/RadioList.vue";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import Dropdown from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/Dropdown.vue";
import { mapGetters, mapMutations } from "vuex";
import deepEqual from "deep-equal";
import cloneDeep from "lodash.clonedeep";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUndo, faHistory } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { validationMixin, resolveErrorMessage } from "@/validation";
import { getInvitationSettingsSchema } from "@/validation/schemas/invitationSettings";
import SettingsBlock from "@/components/Settings/Molecules/SettingsBlock.vue";
import SettingsSection from "@/components/Settings/Molecules/SettingsSection.vue";
import SettingsFooter from "@/components/Settings/Molecules/SettingsFooter.vue";
import SettingsForm from "@/components/Settings/Molecules/SettingsForm.vue";
import NotificationBanner from "@/components/Global/Molecules/NotificationBanner.vue";

const UPDATE_DIVISION_SETTINGS_ACTION = "InvitationsStore/Division/updateDivisionSettings";
let unsubscribeDivisionSettingsAction = () => {};

library.add(faUndo, faHistory);

export default {
    name: "Invitations",
    mixins: [validationMixin],
    components: {
        Button,
        Dropdown,
        EmailInputList,
        SettingsFooter,
        NaturalLanguageInput,
        SettingsBlock,
        SettingsSection,
        SettingsForm,
        SettingsHeader,
        RadioList,
        ValidationMessage,
        FontAwesomeIcon,
        NotificationBanner,
    },
    data() {
        return {
            localSettings: null,
            isReady: false,
            isSaving: false,
            isOutdated: false,
        };
    },
    validations() {
        return getInvitationSettingsSchema(this.localSettings);
    },
    computed: {
        ...mapGetters({
            activeAccount: "AccountStore/activeAccount",
        }),
        divisionId() {
            return this.$store.state.InvitationsStore.Division.divisionSettings.data.divisionId;
        },
        formIsDirty() {
            return !deepEqual(
                this.localSettings,
                this.$store.state.InvitationsStore.Division.divisionSettings.data.settings
            );
        },
        resolveRepeatInvitesPeriodErrorMessage() {
            const valueError = resolveErrorMessage(
                this.$v.localSettings.inviteSettings.repeatInvitesPeriod.value
            );

            if (valueError.length) return valueError;

            const unitError = resolveErrorMessage(
                this.$v.localSettings.inviteSettings.repeatInvitesPeriod.unit
            );

            if (unitError.length) return unitError;

            return "";
        },
        notificationEmailsErrorMap() {
            return Object.values(
                this.$v.localSettings.notificationSettings.notificationEmails.$each.$iter
            ).map((notificationEmail) => notificationEmail.$error);
        },
        notificationsEmailErrorMessagesMap() {
            return Object.values(
                this.$v.localSettings.notificationSettings.notificationEmails.$each.$iter
            ).map((notificationEmail) => this.resolveErrorMessage(notificationEmail));
        },
    },
    methods: {
        ...mapMutations({
            setStoreState: "InvitationsStore/Division/set",
        }),
        resolveErrorMessage,
        populateLocalData() {
            this.stubPeriodDTO();
            this.localSettings = cloneDeep(
                this.$store.state.InvitationsStore.Division.divisionSettings.data.settings
            );
        },
        stubPeriodDTO() {
            const stub = { value: null, unit: null };

            const settings = {
                ...this.$store.state.InvitationsStore.Division.divisionSettings.data.settings,
            };

            if (settings.notificationSettings.apiConnectionWarningPeriod === null) {
                this.setStoreState({
                    name: "getDivisionSettings",
                    value: {
                        divisionId: this.divisionId,
                        settings: {
                            ...this.$store.state.InvitationsStore.Division.divisionSettings.data
                                .settings,
                            notificationSettings: {
                                ...this.$store.state.InvitationsStore.Division.divisionSettings.data
                                    .settings.notificationSettings,
                                apiConnectionWarningPeriod: { ...stub, unit: "DAYS" },
                            },
                        },
                    },
                });
            }

            if (settings.inviteSettings.defaultReminderPeriod === null) {
                this.setStoreState({
                    name: "getDivisionSettings",
                    value: {
                        divisionId: this.divisionId,
                        settings: {
                            ...this.$store.state.InvitationsStore.Division.divisionSettings.data
                                .settings,
                            inviteSettings: {
                                ...this.$store.state.InvitationsStore.Division.divisionSettings.data
                                    .settings.inviteSettings,
                                defaultReminderPeriod: { ...stub, unit: "DAYS" },
                            },
                        },
                    },
                });
            }

            if (settings.inviteSettings.repeatInvitesPeriod === null) {
                this.setStoreState({
                    name: "getDivisionSettings",
                    value: {
                        divisionId: this.divisionId,
                        settings: {
                            ...this.$store.state.InvitationsStore.Division.divisionSettings.data
                                .settings,
                            inviteSettings: {
                                ...this.$store.state.InvitationsStore.Division.divisionSettings.data
                                    .settings.inviteSettings,
                                repeatInvitesPeriod: { ...stub, unit: "DAYS" },
                            },
                        },
                    },
                });
            }
        },
        getPayload() {
            const payload = cloneDeep(this.localSettings);
            const { notificationSettings, inviteSettings } = this.localSettings;
            const periodDTOIsStub = (periodDTO) =>
                periodDTO.value === null || periodDTO.unit === null;

            // un-stubbing the PeriodDTO when their original value was null and should remain so.
            if (periodDTOIsStub(notificationSettings.apiConnectionWarningPeriod)) {
                payload.notificationSettings.apiConnectionWarningPeriod = null;
            }

            if (periodDTOIsStub(inviteSettings.defaultReminderPeriod)) {
                payload.inviteSettings.defaultReminderPeriod = null;
            }

            if (periodDTOIsStub(inviteSettings.repeatInvitesPeriod)) {
                payload.inviteSettings.repeatInvitesPeriod = null;
            }

            return payload;
        },
        saveSettings() {
            this.$v.$touch();

            if (this.$v.$error) {
                this.$store.dispatch("pushNotification", {
                    type: "error",
                    title: this.$t("cp__pn__config_invitation__error__title"),
                    message: this.$t("cp__pn__config_invitation__error__message"),
                });

                return;
            }

            this.$v.$reset();
            this.$store.dispatch(UPDATE_DIVISION_SETTINGS_ACTION, {
                params: { id: this.divisionId },
                body: { settings: this.getPayload() },
            });
        },
        reloadForm() {
            this.populateLocalData();
            this.isOutdated = false;
            this.$store.dispatch("pushNotification", {
                type: "success",
                title: this.$t("cp__pn__conflict_resolution_invitation__success__title"),
                message: this.$t("cp__pn__conflict_resolution_invitation__success__message"),
            });
        },
        subscribeForWebsocketMessages() {
            this.$store.dispatch("InvitationsStore/Division/subscribe", {
                topic: `/topic/division-settings-state.${this.divisionId}`,
                messageHandler: this.handleIncomingWebsocketMessage,
            });
        },
        handleIncomingWebsocketMessage(data) {
            this.setStoreState({
                name: "getDivisionSettings",
                value: data,
            });
            this.stubPeriodDTO();
            if (this.formIsDirty) {
                this.isOutdated = true;
            }
        },
    },
    mounted() {
        this.$store
            .dispatch("InvitationsStore/Division/getDivisionSettings", {
                externalId: this.activeAccount.externalId,
            })
            .then(() => {
                this.populateLocalData();
                this.isReady = true;
                // after getDivisionSettings because we need divisionId first.
                this.subscribeForWebsocketMessages();
            });

        unsubscribeDivisionSettingsAction = this.$store.subscribeAction({
            before: (action) => {
                if (action.type !== UPDATE_DIVISION_SETTINGS_ACTION) return;
                this.isSaving = true;
            },
            after: (action) => {
                if (action.type !== UPDATE_DIVISION_SETTINGS_ACTION) return;
                this.isSaving = false;

                if (!this.$store.state.InvitationsStore.Division.divisionSettings.errors.length) {
                    this.$store.dispatch("pushNotification", {
                        type: "success",
                        title: this.$t("cp__pn__config_invitation__success__title"),
                        message: this.$t("cp__pn__config_invitation__success__message"),
                    });
                } else {
                    this.$store.dispatch("pushNotification", {
                        type: "error",
                        title: this.$t("cp__pn__config_invitation__error__title"),
                        message: this.$t("cp__pn__config_invitation__error__message"),
                    });
                }
            },
        });
    },
    beforeDestroy() {
        if (!this.divisionId) return;
        this.$store.dispatch("InvitationsStore/Division/unsubscribe", {
            topic: `/topic/division-settings-state.${this.divisionId}`,
        });
    },
    destroyed() {
        unsubscribeDivisionSettingsAction();
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";
@import "~include-media";
$content_width: 900px;
$control_height: 96px;

.settings_form {
    padding: 0 24px;
    @include media($isPhone...) {
        padding: 0;
    }
}

.config {
    position: relative;
    @include viewportHeight(100, calc(56px + #{$control_height}));
    overflow: auto;

    @media (max-width: 1000px) {
        @include viewportHeight(100, 144px);
    }
}
.config__header__with_conflict {
    margin-top: 50px;
}
.config__block {
    max-width: $content_width;
    margin-top: 40px;
}
.natural_language_input__dropdown {
    display: inline-block;
    width: 140px;
}
.conflict_banner {
    position: fixed;
    width: 100%;
    z-index: 3;
    font-weight: 300;
}
.conflict_banner__icon {
    margin-right: 12px;
}
.conflict_banner__link {
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
}
.btn__reload_form {
    margin-right: 12px;
    .action__action_icon {
        margin: 3px 10px 0 0;
    }
}
</style>
