import Vue from "vue";
import { email, minValue, numeric, required, isOneOf, requiredIf } from "@/validation/validators";

const { $t } = Vue.prototype;

export const getInvitationSettingsSchema = (model) => {
    const { notificationSettings, inviteSettings } = model;
    const allowedUnits = ["HOURS", "DAYS", "WEEKS", "MONTHS", "YEARS"];
    const allowedUnitLabels = [
        $t("generic__timeunits__hours", "Hours"),
        $t("generic__timeunits__days", "Days"),
        $t("generic__timeunits__weeks", "Weeks"),
        $t("generic__timeunits__months", "Months"),
    ];

    const valuePeriodConstraints = {
        numeric,
        minValue: minValue(1),
        required,
    };

    const getPeriodConstraints = (shouldBeApplied) => {
        if (!shouldBeApplied) return { value: {}, unit: {} };

        return {
            value: valuePeriodConstraints,
            unit: {
                required,
                isOneOf: isOneOf(allowedUnits, allowedUnitLabels),
            },
        };
    };

    return {
        localSettings: {
            notificationSettings: {
                notificationEmails: {
                    $each: {
                        email,
                        required: requiredIf(() => {
                            const isOnlyOne =
                                model.notificationSettings.notificationEmails.length === 1;
                            const areAllEmpty =
                                model.notificationSettings.notificationEmails.join("") === "";
                            return isOnlyOne || areAllEmpty;
                        }),
                    },
                },
                apiRequestFailNotificationEnabled: { required },
                apiConnectionWarningEnabled: { required },
                apiConnectionWarningPeriod: getPeriodConstraints(
                    notificationSettings.apiConnectionWarningEnabled
                ),
            },
            inviteSettings: {
                defaultReminderEnabled: { required },
                defaultReminderPeriod: getPeriodConstraints(inviteSettings.defaultReminderEnabled),
                repeatInvitesSuppressionEnabled: { required },
                repeatInvitesPeriod: getPeriodConstraints(
                    inviteSettings.repeatInvitesSuppressionEnabled
                ),
            },
        },
    };
};
