var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReady)?_c('div',{staticClass:"config"},[(_vm.isOutdated)?_c('NotificationBanner',{staticClass:"conflict_banner",attrs:{"theme":"red","closeable":false},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-icon',{staticClass:"conflict_banner__icon",attrs:{"size":"lg","icon":['far', 'history']}})]},proxy:true},{key:"default",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t( "cp__invitations__settings__outdated_settings", "Looks like you have outdated invitation settings." ))+" "),_c('a',{staticClass:"conflict_banner__link",on:{"click":_vm.reloadForm}},[_vm._v(" "+_vm._s(_vm.$t("cp__invitations__settings__outdated_settings__link", "Reload the form"))+" ")]),_vm._v(" "+_vm._s(_vm.$t( "cp__invitations__settings__outdated_settings__get_updated_version", "to get the updated version." ))+" ")]},proxy:true}],null,false,1404511176)}):_vm._e(),_c('SettingsHeader',{class:{ config__header__with_conflict: _vm.isOutdated },attrs:{"title":_vm.$t('config_invitation__title', 'Invitation Settings'),"subtitle":_vm.$t(
                'config_invitation__helptext',
                'Here you can update your invitation notifications and settings to optimise your feedback service.'
            ),"backButtonTo":{ name: 'Invitations' },"backButtonLabel":_vm.$t('cp__invitations__settings__back_button')}}),_c('SettingsForm',{staticClass:"settings_form"},[_c('SettingsBlock',{staticClass:"config__block",attrs:{"title":_vm.$t('config_invitation__notifications__title', 'Notifications')}},[_c('SettingsSection',{attrs:{"label":_vm.$t(
                        'config_invitation__notifications__api_email__label',
                        'API Notification email address'
                    ),"helpText":_vm.$t(
                        'config_invitation__notifications__api_email__helptext',
                        'The Notification Mails regarding the API will be send here.'
                    )}},[_c('EmailInputList',{staticClass:"notification_emails",attrs:{"maxSize":10,"enableExternalValidation":true,"externalValidationErrors":_vm.notificationEmailsErrorMap,"externalValidationErrorMessages":_vm.notificationsEmailErrorMessagesMap,"addAnotherLabel":_vm.$t('cp__add_another_label')},model:{value:(_vm.localSettings.notificationSettings.notificationEmails),callback:function ($$v) {_vm.$set(_vm.localSettings.notificationSettings, "notificationEmails", $$v)},expression:"localSettings.notificationSettings.notificationEmails"}})],1),_c('SettingsSection',{attrs:{"label":_vm.$t(
                        'config_invitation__notifications__api_request_fail_warning__label',
                        'Send notifications'
                    ),"helpText":_vm.$t(
                        'config_invitation__notifications__api_request_fail_warning__helptext',
                        'This notifies you when a couple API calls fail in a row.'
                    )}},[_c('RadioList',{attrs:{"required":"","enableExternalValidation":"","options":[
                        {
                            value: false,
                            displayValue: _vm.$t(
                                'config_invitation__notifications__api_request_fail_warning__label__no_notification'
                            ),
                        },
                        {
                            value: true,
                            displayValue: _vm.$t(
                                'config_invitation__notifications__api_request_fail_warning__label__send_notification'
                            ),
                        } ]},model:{value:(
                        _vm.localSettings.notificationSettings.apiRequestFailNotificationEnabled
                    ),callback:function ($$v) {_vm.$set(_vm.localSettings.notificationSettings, "apiRequestFailNotificationEnabled", $$v)},expression:"\n                        localSettings.notificationSettings.apiRequestFailNotificationEnabled\n                    "}})],1),_c('SettingsSection',{attrs:{"label":_vm.$t(
                        'config_invitation__notifications__api_connection_fail_warning__label',
                        'Connection warning'
                    ),"helpText":_vm.$t(
                        'config_invitation__notifications__api_connection_fail_warning__helptext',
                        'This notification can warn you when no successful order was received for an amount of days.'
                    )}},[_c('RadioList',{attrs:{"required":"","enableExternalValidation":"","options":[
                        {
                            value: false,
                            displayValue: _vm.$t(
                                'config_invitation__notifications__no_notifications',
                                'No notifications'
                            ),
                        },
                        {
                            name: 'apiConnectionWarningPeriod',
                            value: true,
                            isComponent: true,
                        } ]},scopedSlots:_vm._u([{key:"apiConnectionWarningPeriod",fn:function(){return [_c('NaturalLanguageInput',{attrs:{"center":"","label":_vm.$t(
                                    'config_invitation__notifications__nli__send_notifications_after',
                                    'Send notifications [[value]] days'
                                ),"inputAttributes":{
                                type: 'number',
                                style: 'text-align: center; width: 56px;',
                            },"validationError":_vm.$v.localSettings.notificationSettings.apiConnectionWarningPeriod
                                    .value.$error},model:{value:(
                                _vm.localSettings.notificationSettings.apiConnectionWarningPeriod
                                    .value
                            ),callback:function ($$v) {_vm.$set(_vm.localSettings.notificationSettings.apiConnectionWarningPeriod
                                    , "value", $$v)},expression:"\n                                localSettings.notificationSettings.apiConnectionWarningPeriod\n                                    .value\n                            "}})]},proxy:true}],null,false,2817456914),model:{value:(_vm.localSettings.notificationSettings.apiConnectionWarningEnabled),callback:function ($$v) {_vm.$set(_vm.localSettings.notificationSettings, "apiConnectionWarningEnabled", $$v)},expression:"localSettings.notificationSettings.apiConnectionWarningEnabled"}}),_c('ValidationMessage',{attrs:{"displayInvalid":_vm.$v.localSettings.notificationSettings.apiConnectionWarningPeriod.value
                            .$error,"validationMessage":_vm.resolveErrorMessage(
                            _vm.$v.localSettings.notificationSettings.apiConnectionWarningPeriod
                                .value
                        )}})],1)],1),_c('SettingsBlock',{staticClass:"config__block",attrs:{"title":_vm.$t('config_invitation__invitations__title', 'Invitations')}},[_c('SettingsSection',{attrs:{"label":_vm.$t('config_invitation__invite__default_reminder__label', 'Default reminder'),"helpText":_vm.$t(
                        'config_invitation__invite__default_reminder__helptext',
                        'When creating a manual invite in the Customer Portal, the reminder will be send:'
                    )}},[_c('RadioList',{attrs:{"required":"","enableExternalValidation":"","options":[
                        {
                            value: false,
                            displayValue: _vm.$t(
                                'config_invitation__invite__no_default_reminder',
                                'No default reminder'
                            ),
                        },
                        {
                            name: 'defaultReminderPeriod',
                            value: true,
                            isComponent: true,
                        } ]},scopedSlots:_vm._u([{key:"defaultReminderPeriod",fn:function(){return [_c('NaturalLanguageInput',{attrs:{"center":"","label":_vm.$t(
                                    'config_invitation__invitations__nli__remind_date_is',
                                    'After [[value]] days'
                                ),"inputAttributes":{ type: 'number' },"validationError":_vm.$v.localSettings.inviteSettings.defaultReminderPeriod.value
                                    .$error},model:{value:(_vm.localSettings.inviteSettings.defaultReminderPeriod.value),callback:function ($$v) {_vm.$set(_vm.localSettings.inviteSettings.defaultReminderPeriod, "value", $$v)},expression:"localSettings.inviteSettings.defaultReminderPeriod.value"}})]},proxy:true}],null,false,2406869270),model:{value:(_vm.localSettings.inviteSettings.defaultReminderEnabled),callback:function ($$v) {_vm.$set(_vm.localSettings.inviteSettings, "defaultReminderEnabled", $$v)},expression:"localSettings.inviteSettings.defaultReminderEnabled"}}),_c('ValidationMessage',{attrs:{"displayInvalid":_vm.$v.localSettings.inviteSettings.defaultReminderPeriod.value.$error,"validationMessage":_vm.resolveErrorMessage(
                            _vm.$v.localSettings.inviteSettings.defaultReminderPeriod.value
                        )}})],1),_c('SettingsSection',{attrs:{"label":_vm.$t('config_invitation__invite__repeat_invites__label', 'Repeat Invites'),"helpText":_vm.$t(
                        'config_invitation__invite__repeat_invites__helptext',
                        'After receiving an invitation, the customer will not receive any new invitations for this amount of time:'
                    )}},[_c('RadioList',{attrs:{"required":"","enableExternalValidation":"","options":[
                        {
                            value: false,
                            displayValue: _vm.$t(
                                'config_invitation__invitations__always_send_invitations',
                                'Always send invitations.'
                            ),
                        },
                        {
                            name: 'repeatInvitesSuppressionEnabled',
                            value: true,
                            isComponent: true,
                        } ]},scopedSlots:_vm._u([{key:"repeatInvitesSuppressionEnabled",fn:function(){return [_c('NaturalLanguageInput',{attrs:{"center":"","label":_vm.$t(
                                    'config_invitation__invitations__send_no_new_invitations_within'
                                ),"inputAttributes":{ type: 'number' },"validationError":_vm.$v.localSettings.inviteSettings.repeatInvitesPeriod.value.$error},model:{value:(_vm.localSettings.inviteSettings.repeatInvitesPeriod.value),callback:function ($$v) {_vm.$set(_vm.localSettings.inviteSettings.repeatInvitesPeriod, "value", $$v)},expression:"localSettings.inviteSettings.repeatInvitesPeriod.value"}},[_c('div',{staticClass:"natural_language_input__dropdown"},[_c('Dropdown',{attrs:{"required":"","compact":"","fontWeightRegular":"","placeholder":_vm.$t(
                                            'config_invitation__invitations__timespan_dropdown_placeholder',
                                            'Time'
                                        ),"enableExternalValidation":"","externalValidationError":_vm.$v.localSettings.inviteSettings.repeatInvitesPeriod.unit
                                            .$error,"externalValidationErrorMessage":"","options":[
                                        {
                                            value: 'HOURS',
                                            displayValue: _vm.$t(
                                                'generic__timeunits__hours',
                                                'Hours'
                                            ),
                                        },
                                        {
                                            value: 'DAYS',
                                            displayValue: _vm.$t(
                                                'generic__timeunits__days',
                                                'Days'
                                            ),
                                        },
                                        {
                                            value: 'WEEKS',
                                            displayValue: _vm.$t(
                                                'generic__timeunits__weeks',
                                                'Weeks'
                                            ),
                                        },
                                        {
                                            value: 'MONTHS',
                                            displayValue: _vm.$t(
                                                'generic__timeunits__months',
                                                'Months'
                                            ),
                                        } ]},model:{value:(
                                        _vm.localSettings.inviteSettings.repeatInvitesPeriod.unit
                                    ),callback:function ($$v) {_vm.$set(_vm.localSettings.inviteSettings.repeatInvitesPeriod, "unit", $$v)},expression:"\n                                        localSettings.inviteSettings.repeatInvitesPeriod.unit\n                                    "}})],1)])]},proxy:true}],null,false,334331891),model:{value:(_vm.localSettings.inviteSettings.repeatInvitesSuppressionEnabled),callback:function ($$v) {_vm.$set(_vm.localSettings.inviteSettings, "repeatInvitesSuppressionEnabled", $$v)},expression:"localSettings.inviteSettings.repeatInvitesSuppressionEnabled"}}),_c('ValidationMessage',{attrs:{"displayInvalid":_vm.$v.localSettings.inviteSettings.repeatInvitesPeriod.value.$error ||
                        _vm.$v.localSettings.inviteSettings.repeatInvitesPeriod.unit.$error,"validationMessage":_vm.resolveRepeatInvitesPeriodErrorMessage}})],1)],1)],1),_c('SettingsFooter',[(_vm.isOutdated)?_c('Button',{staticClass:"btn__reload_form",nativeOn:{"click":function($event){return _vm.reloadForm.apply(null, arguments)}}},[_c('font-awesome-icon',{staticClass:"action__action_icon",attrs:{"icon":['far', 'undo']}}),_c('span',[_vm._v(_vm._s(_vm.$t("portal instellingen (reload button)", "Reload form")))])],1):_vm._e(),_c('Button',{attrs:{"disabled":_vm.isOutdated || !_vm.formIsDirty,"loading":_vm.isSaving},nativeOn:{"click":function($event){return _vm.saveSettings.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("portal instellingen (button)", "Save changes"))+" ")])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }